import logo from "./logo.svg";
import "./App.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import videophoto from "./photo.mp4";
import bgvideo from "./background.mp4";
import vodalogo from "./vodalogo.png";
import axios from "axios";
import { Audio } from "react-loader-spinner";
function App() {
  const [todos, setTodos] = useState([]);

  function shuffleArray(array) {
    // Create a copy of the original array to avoid mutating it
    const shuffledArray = array.slice();

    // Fisher-Yates Shuffle Algorithm
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index from 0 to i
      const j = Math.floor(Math.random() * (i + 1));

      // Swap elements at indices i and j
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }

  const fetchPost = async () => {
    axios
      .get("https://link3.imc.co.tz/items")
      .then(({ data }) => {
        console.log(data);
        setTodos(data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };
  useEffect(() => {
    fetchPost();
    const interval = setInterval(() => {
      fetchPost();
    }, 2000); // Fetch every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTodos((data) => shuffleArray(data));
    }, 5000); // Fetch every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component
  }, []);
  return (
    <div className="App">
      <header className="App-header"></header>
      <video
        src={bgvideo}
        muted
        autoPlay
        loop
        style={{ opacity: "0.4", position: "absolute" }}
      ></video>
      <div className="gallery">
        {todos.map((photo, index) => {
          console.log(photo.description);
          return (
            <div key={index} className="photo">
              <nav>
                <nav
                  style={{
                    width: "10px",
                  }}
                >
                  <img src={vodalogo} alt="" />
                </nav>
              </nav>
              <video
                src={videophoto}
                muted
                autoPlay
                loop
                style={{
                  position: "absolute",
                  transform: "scale(1.2",
                  opacity: "0.2",
                }}
              ></video>
              <div>
                <img src={photo.description} alt={`Photo ${index + 1}`} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
